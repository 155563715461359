import React from "react";
import { Container, Grid, Typography} from "@material-ui/core";
// import Grid from '@material-ui/core/Grid';

import './Home.scss';

import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);


const Home = () => {
    return (
    <Container maxWidth="lg">
        <Grid container spacing={3} className="container">
            <Grid item xs={12}>

            <ThemeProvider theme={theme}>
                <Typography variant="h1" class="title">Welcome to Kondria.</Typography>
                <Typography variant="h3" class="subtitle">Our goal is to improve activism through sustainable resources and digital tools.</Typography>
                <Typography variant="h4" class="subsubtitle">Stay tuned.</Typography>
            </ThemeProvider>

                {/* <h1 class='title'>Welcome to Kondria.</h1>
                <h2 class='subtitle'>
                Our goal is to improve activism through sustainability resources and digital tools.
                </h2>
                <h2 class='subsubtitle'>Stay Tuned.</h2> */}
            </Grid>
        </Grid>
    </Container>
    )
};

export default Home;

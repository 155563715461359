import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Page from './components/Page/Page';

import Home from "./pages/Home/Home";

// needed? https://github.com/srph/axios-response-logger
import 'axios-response-logger';

const App = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Page>
          <Switch>
            {/* Root */}
            <Route exact path="/" render={() => <Home />} />
            <Redirect to="/" />
          </Switch>
        </Page>
      </BrowserRouter>
    </div>
  );
};

export default App;
